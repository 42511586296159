import {sanitizeOrganizationId} from '@core/configuration';
import {OrganizationLocalStorage} from './OrganizationLocalStorage';
import {OrganizationSelectors} from './selectors';
import {OrganizationStore} from './store';

const EXTRACT_ORG_ID_FROM_URL_HASH = /#\/(\w+)/;

const getId = () =>
    OrganizationSelectors.getOrganizationId(OrganizationStore.getState()) ||
    sanitizeOrganizationId(EXTRACT_ORG_ID_FROM_URL_HASH.exec(window.location.hash)?.[1]) ||
    OrganizationLocalStorage.getOrganizationFromLocalStorage() ||
    null;

export const CurrentOrganization = {
    getId,
};
